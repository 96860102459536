export default class TimerStore {
  constructor(duration) {
    this.duration = duration
    this.end = new Date().getTime() + duration
  }

  inform(sub) {
    const now = new Date().getTime()
    sub(this.end - now)
    return this.end < now
  }

  subscribe(sub) {
    const done = this.inform(sub)
    if (done) {
      return function () {} // no-op
    }

    const i = setInterval(() => {
      const done = this.inform(sub)
      if (done) {
        clearInterval(i)
      }
    }, 100)

    return () => {
      clearInterval(i)
    }
  }
}

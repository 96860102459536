<script>
  import { createEventDispatcher } from 'svelte'

  import TimerStore from '@/stores/timer.js'

  export let ms = null
  export let at = null
  export let text = 'Offer expires'
  export let whiteTimerText = false
  export let boldText = false
  export let whiteText = false
  export let redText = false
  export let timeInSeconds = 10 * 60 * 1000
  export let whiteTimer = false

  if (!at && ms) {
    at = new Date().getTime() + ms
  }

  const timer = new TimerStore(timeInSeconds)
  const dispatch = createEventDispatcher()

  $: min = Math.floor($timer / 1000 / 60)
  $: sec = Math.floor($timer / 1000 - min * 60)

  $: strmin = min.toString().padStart(2, '0')
  $: strsec = sec.toString().padStart(2, '0')

  $: if ($timer <= 0) dispatch('end')
</script>

<div class="font-roboto text-black-1">
  {#if $timer > 0}
    <div class="flex flex-col lg:flex-row">
      <p
        class:bold={boldText}
        class:text-white={whiteText}
        class:text-red={redText}
        class="font-inter font-semibold lg:text-base lg:mr-2"
      >
        {text}
      </p>
      <div
        class="inline-flex flex-row items-center font-semibold font-roboto"
        class:text-red={redText}
      >
        {#each strmin as n}
          <span
            class="inline-block px-[5px] rounded mx-0.5"
            class:bg-green={whiteTimerText}
            class:bg-white={!whiteTimerText && !whiteTimer}
            class:bg-black-1={whiteTimer}
            class:mx-[2px]={whiteTimer}
            class:text-white={whiteTimerText || whiteTimer}>{n}</span
          >
        {/each}
        <span class="mx-0.5" class:text-white={whiteText}>:</span>

        {#each strsec as n}
          <span
            class="inline-block px-[5px] rounded mx-0.5"
            class:bg-green={whiteTimerText}
            class:bg-white={!whiteTimerText && !whiteTimer}
            class:bg-black-1={whiteTimer}
            class:mx-[2px]={whiteTimer}
            class:text-white={whiteTimerText || whiteTimer}>{n}</span
          >
        {/each}
      </div>
    </div>
  {:else}
    <slot />
  {/if}
</div>
